<template>
  <div>
    <v-toolbar flat>
      <v-spacer></v-spacer>
      <div class="card-header-form">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model="params.search"
            placeholder="Search"
          />
          <div class="input-group-btn">
            <button
              class="btn btn-primary"
              @click="fetchListRoles(params)"
              style="
                border-radius: 0px;
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
              "
            >
              <i class="fas fa-search" style="border-radius: 0 !important"></i>
            </button>
          </div>

          <v-btn
            color="error"
            class="ml-5"
            elevation="5"
            @click="dialogDelete = !dialogDelete"
            :disabled="dataDelete.length < 1"
            v-if="checkUserPermission('DELETE')"
          >
            {{ $t("button.delete_all") }}
          </v-btn>
          
          <v-btn
            class="ml-5"
            elevation="5"
            color="primary"
            @click="createDialog"
            v-if="checkUserPermission('CREATE', userPermission)"
          >
            {{ $t("button.create") }}
          </v-btn>
          <dialog-delete
            :dialog-delete="dialogDelete"
            :darkmode="darkmode"
            v-on:closeDelete="closeDelete"
            v-on:deleteItemConfirm="deleteItemConfirm"
          />
        </div>
      </div>
    </v-toolbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DialogDelete from "../../../components/shared/notify/DialogDelete";

export default {
  components: { DialogDelete },
  props: ["darkmode", "status", "params", "dataDelete", "userPermission"],
  data() {
    return {
      dialogDelete: false,
    };
  },
  methods: {
    closeDelete() {
      this.dialogDelete = !this.dialogDelete;
    },
    async deleteItemConfirm() {
      await this.deleteRole({
        vm: this,
        data: { deleteItems: this.dataDelete },
      });
      await this.fetchListRoles(this.params);
      this.dialogDelete = false;
    },
    async createDialog() {
      await this.fetchTreeMenu();
      this.fetchPopUp(true);
    },
    ...mapActions("$_menu", ["fetchTreeMenu"]),
    ...mapActions("$_role", ["fetchPopUp", "fetchListRoles", "deleteRole"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
